import {IS_MAIN_LOADING, IS_SCROLL_WANTED} from 'Containers/AppGeneral/consts'
import {APP_GENERAL, CITIES, STREETS} from 'Redux/consts'
import {createSelector} from 'reselect';

const _getIsMainLoading = (state) => state.getIn([APP_GENERAL, IS_MAIN_LOADING]);
const _getIsScrollWanted = (state) => state.getIn([APP_GENERAL, IS_SCROLL_WANTED]);
const _getReducer = (state) => state.get(APP_GENERAL);

export const getIsMainLoading = createSelector(
    _getIsMainLoading,
    isMainLoading => isMainLoading,
);
export const getIsScrollWanted = createSelector(
  _getIsScrollWanted,
  isScrollWanted => isScrollWanted,
)
export const getCities = createSelector(
  _getReducer,
  reducer => reducer.get(CITIES).toJS(),
)
export const getStreets = createSelector(
  _getReducer,
  reducer => reducer.get(STREETS).toJS(),
)
export const getCitiesLength = (state) => state.getIn([APP_GENERAL,CITIES]).size
export const getStreetsLength = (state) => state.getIn([APP_GENERAL,STREETS]).size
