import Subscribers from 'Components/Subsubcribers'
import {locateSubscriber} from 'Containers/Subscribers/actions'
import {connect} from 'react-redux';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    locateSubscriber:({locateType, idNumber, lineCode}) => dispatch(locateSubscriber({locateType, idNumber, lineCode})),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribers);
