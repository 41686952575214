import {apiMockupServer} from 'Api/Backend'
import {
    loadTicketsResult, loadSingleTicketResult,
    retrieveTicketWs7Result,
    setCreateTicketIsLoading,
    setEditTicketFormValues,
    setLoadTicketsIsLoading,
    setLoadSingleTicketIsLoading,
    setRetrieveTicketIsLoading,
} from 'Containers/Tickets/actions'
import {CREATE_TICKET_WS8, LOAD_TICKETS, LOAD_SINGLE_TICKET, RETRIEVE_TICKET_WS7} from 'Containers/Tickets/consts'
import {getCreateTicketFormValues} from 'Containers/Tickets/selectors'
import {toastr} from 'Helpers/toastr'
import {sleep} from 'Helpers/utils'
import {takeEvery, takeLeading, call, put, select} from 'redux-saga/effects'

function *createTicketWs8Saga({redirect}) {
    yield put(setCreateTicketIsLoading(true))
    try {
        const formData = yield select(getCreateTicketFormValues)
        const result = yield call(apiMockupServer.post, 'CreateTicket', formData)
        yield call(sleep, 2000)
        redirect(`/tickets/${result?.ticketId}`)
    } catch (e) {
        console.error(e)
        toastr.error('שגיאה ביצירת תקלה');
    }
    yield put(setCreateTicketIsLoading(false))
}

function *loadTicketsSaga({limit, offset, keyword}) {
    yield put(setLoadTicketsIsLoading(true))
    try {
        const result = yield call(apiMockupServer.get, 'LoadTickets', {params: {limit,  offset, keyword}})
        yield call(sleep, 1000)
        yield put(loadTicketsResult(result))

    } catch (e) {
        console.error(e)
    }
    yield put(setLoadTicketsIsLoading(false))
}

function *loadSingleTicketSaga({ticketId}) {
    yield put(setLoadSingleTicketIsLoading(true))
    try {
        const result = yield call(apiMockupServer.get, 'LoadTicket', {params: {ticketId}})
        yield call(sleep, 1000)
        yield put(loadSingleTicketResult(result))
        yield put(setEditTicketFormValues(result))
    } catch (e) {
        console.error(e)
    }
    yield put(setLoadSingleTicketIsLoading(false))
}

function *retrieveTicketWs7Saga({ticketId}) {
    yield put(setRetrieveTicketIsLoading(true))
    try {
        const result = yield call(apiMockupServer.put, 'RetrieveTicket', {ticketId:Number(ticketId)})
        yield call(sleep, 2000)
        yield put(retrieveTicketWs7Result(result))
    } catch (e) {
        console.error(e)
        toastr.error('שגיאה באחזור סטטוס תקלה');
    }
    yield put(setRetrieveTicketIsLoading(false))
}

export default function* TicketsSaga() {
    yield takeEvery(CREATE_TICKET_WS8, createTicketWs8Saga)
    yield takeLeading(LOAD_TICKETS, loadTicketsSaga)
    yield takeEvery(LOAD_SINGLE_TICKET, loadSingleTicketSaga)
    yield takeEvery(RETRIEVE_TICKET_WS7, retrieveTicketWs7Saga)
}
