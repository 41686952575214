import AdminConsoleReducer from 'Containers/AdminConsole/reducer'
import AppGeneralReducer from 'Containers/AppGeneral/reducer'
import AuthReducer from 'Containers/Auth/reducer'
import OrdersReducer from 'Containers/Orders/reducer'
import SubscribersReducer from 'Containers/Subscribers/reducer'
import CheckLinesReducer from 'Containers/CheckLines/reducer'
import TicketsReducer from 'Containers/Tickets/reducer'
import {combineReducers} from "redux-immutable";
import { connectRouter } from 'connected-react-router/immutable';
import {reducer as ToastrReducer} from 'react-redux-toastr'
import {ADMIN_CONSOLE, APP_GENERAL, AUTH, CHECK_LINES, ORDERS, SUBSCRIBERS, TICKETS} from 'Redux/consts'

const reducers = (history) => combineReducers({
    toastr: ToastrReducer,
    router: connectRouter(history),
    [AUTH]: AuthReducer,
    [SUBSCRIBERS]: SubscribersReducer,
    [APP_GENERAL]: AppGeneralReducer,
    [ORDERS]: OrdersReducer,
    [CHECK_LINES]: CheckLinesReducer,
    [TICKETS]: TicketsReducer,
    [ADMIN_CONSOLE]: AdminConsoleReducer,
});
export default reducers
