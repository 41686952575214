import apiServer, {apiMockupServer} from 'Api/Backend'
import {setIsScrollWanted} from 'Containers/AppGeneral/actions'
import {
    loadOrdersResult, loadSingleOrderResult, locateByGisWs16Result,
    locateCustomerWs1Result, retrieveOrderWs3Result, setActivateGponIsLoading, setCreateOrderFormValues,
    setCreateOrderIsLoading, setCreateTicketIsLoading,
    setEditOrderFormValues, setFinalizeOrderIsLoading,
    setLoadOrdersIsLoading,
    setLoadSingleOrderIsLoading, setLocateByGisFormValues, setLocateByGisIsLoading,
    setLocateCustomerInfraIsLoading, setRetrieveOrderIsLoading,
} from 'Containers/Orders/actions'
import {ACTIVATE_GPON_WS15, CREATE_ORDER_WS2, CREATE_TICKET_FOR_ORDER, FINALIZE_ORDER_WS12, LOAD_ORDERS, LOAD_SINGLE_ORDER, LOCATE_BY_GIS_WS16, LOCATE_CUSTOMER_WS1, RETRIEVE_ORDER_WS3} from 'Containers/Orders/consts'
import {getCreateOrderFormValues} from 'Containers/Orders/selectors'
import {setCreateTicketFormValues} from 'Containers/Tickets/actions'
import {toastr} from 'Helpers/toastr'
import {sleep} from 'Helpers/utils'
import {takeEvery, takeLeading, call, put, select} from 'redux-saga/effects'

function *locateCustomerWs1Saga({formData}) {
    yield put(setLocateCustomerInfraIsLoading(true))
    try {
        yield put(locateCustomerWs1Result(null))
        const {israeliId:idNumber , cityRow:{cityId:locationCode,name:locationName},
            streetRow: {streetId: streetCode, name: streetName}, fourDigits: last4Numbers,
            lineCode, mobileNumber, houseNum, letter, firstName, lastName} = formData
        const payload = {idNumber, lineCode, mobileNumber, locationCode:locationCode.toString(),
            locationName, streetCode:streetCode.toString(),  streetName,last4Numbers,
            houseNum,  letter,  firstName, lastName}
        const result = yield call(apiServer.post, 'Locating/Subscriber', payload)
        yield put(locateCustomerWs1Result({
            messageDesc:result?.errorDescription || result?.messageDesc || (result?.messageCode===0 && 'הצלחה'),
            gponInfo:{gponExists:result?.gpon},
            packageRateMessage: result?.packageRateMassage,
            ...result}))
        yield put(setIsScrollWanted(true))
        const {
            lineCode:_lineCode,
            gponInfo: {
                gponBuildingType,
                gponExists,
            } = {},
        } = result
        yield put(setCreateOrderFormValues({...formData,
            gponBuildingType,
            gponApartment:Number(gponExists)===1,
            lineCode:_lineCode,
        }))
    } catch (e) {
        console.error(e)
    }
    yield put(setLocateCustomerInfraIsLoading(false))
}
function *createOrderWs2Saga({redirect}) {
    yield put(setCreateOrderIsLoading(true))
    try {
        const formData = yield select(getCreateOrderFormValues)
        const result = yield call(apiMockupServer.post, 'CreateOrder', formData)
        yield call(sleep, 2000)
        redirect(`/orders/${result?.orderId}`)
    } catch (e) {
        console.error(e)
    }
    yield put(setCreateOrderIsLoading(false))
}

function *loadOrdersSaga({limit, offset, keyword}) {
    yield put(setLoadOrdersIsLoading(true))
    try {
        const result = yield call(apiMockupServer.get, 'LoadOrders', {params: {limit,  offset, keyword}})
        yield call(sleep, 1000)
        yield put(loadOrdersResult(result))

    } catch (e) {
        console.error(e)
    }
    yield put(setLoadOrdersIsLoading(false))
}

function *loadSingleOrderSaga({orderId}) {
    yield put(setLoadSingleOrderIsLoading(true))
    try {
        const result = yield call(apiMockupServer.get, 'LoadOrder', {params: {orderId}})
        yield call(sleep, 1000)
        yield put(loadSingleOrderResult(result))
        yield put(setEditOrderFormValues(result))
    } catch (e) {
        console.error(e)
    }
    yield put(setLoadSingleOrderIsLoading(false))
}

function *retrieveOrderWs3Saga({orderId}) {
    yield put(setRetrieveOrderIsLoading(true))
    try {
        const result = yield call(apiMockupServer.put, 'RetrieveOrder', {orderId:Number(orderId)})
        yield call(sleep, 2000)
        yield put(retrieveOrderWs3Result(result))
    } catch (e) {
        console.error(e)
        toastr.error('תקלה באחזור סטטוס הזמנה');
    }
    yield put(setRetrieveOrderIsLoading(false))
}

function *finalizeOrderWs12Saga({orderId}) {
    yield put(setFinalizeOrderIsLoading(true))
    try {
        const result = yield call(apiMockupServer.post, 'FinalizeOrder', {orderId:Number(orderId)})
        yield call(sleep, 2000)
        // yield put(retrieveOrderWs3Result(result))
        if (result) {
            toastr.success(`תהליך מכירה וניתוק ספק ישן להזמנה [${orderId}] הסתיים בהצלחה`)
        }
    } catch (e) {
        console.error(e)
        toastr.error('תקלה בסיום תהליך מכירה');
    }
    yield put(setFinalizeOrderIsLoading(false))
}

function *activateGponWs15Saga({orderId, serialNumber, planSoftware}) {
    yield put(setActivateGponIsLoading(true))
    try {
        const result = yield call(apiMockupServer.post, 'ActivateGpon', {orderId:Number(orderId), serialNumber, planSoftware})
        yield call(sleep, 2000)
        // yield put(retrieveOrderWs3Result(result))
        if (result) {
            toastr.success(`איקטוב GPON להזמנה [${orderId}] הסתיים בהצלחה`)
        }
    } catch (e) {
        console.error(e)
        toastr.error('תקלה באיקטוב GPON');
    }
    yield put(setActivateGponIsLoading(false))
}

function *locateByGisWs16Saga({formData}) {
    yield put(setLocateByGisIsLoading(true))
    try {
        yield put(locateByGisWs16Result(null))
        const result = yield call(apiMockupServer.post, 'LocateByGis', formData)
        yield call(sleep, 2000)
        if (result?.messageCode > 0) {
            toastr.error(`שגיאה בחיפוש גאוגרפי ${result?.messageDesc}`)
            yield put(setLocateByGisFormValues({shouldCloseModal: true}))
        } else {
            yield put(locateByGisWs16Result(result))
            const formValues = {
                cityRow: {cityId: result?.locationCode, name: result?.locationName},
                streetRow: {cityId: result?.locationCode, streetId: result?.streetCode, name: result?.streetName},
                houseNum: result?.houseNum,
                houseLetter: result?.letter,
                x: formData?.x,
                y: formData?.y,
            }
            yield put(setLocateByGisFormValues(formValues))
            yield put(setCreateOrderFormValues(formValues))
        }
    } catch (e) {
        toastr.error(`שגיאה באיתור גאוגרפי ${e}`)
        console.error(e)
    }
    yield put(setLocateByGisIsLoading(false))
}

function *createTicketForOrderSaga({orderId, redirect, orderFormValues}) {
    try {
        yield put(setCreateTicketFormValues(orderFormValues))
        yield put(setCreateTicketIsLoading(true))
        yield call(sleep,1000)
        yield put(setCreateTicketIsLoading(false))
        redirect(`/tickets/new/${orderId}`)
    } catch (e) {
        console.error(e)
    }
}


export default function* OrdersSaga() {
    yield takeEvery(LOCATE_CUSTOMER_WS1, locateCustomerWs1Saga)
    yield takeEvery(CREATE_ORDER_WS2, createOrderWs2Saga)
    yield takeLeading(LOAD_ORDERS, loadOrdersSaga)
    yield takeEvery(LOAD_SINGLE_ORDER, loadSingleOrderSaga)
    yield takeEvery(RETRIEVE_ORDER_WS3, retrieveOrderWs3Saga)
    yield takeEvery(FINALIZE_ORDER_WS12, finalizeOrderWs12Saga)
    yield takeEvery(ACTIVATE_GPON_WS15, activateGponWs15Saga)
    yield takeEvery(LOCATE_BY_GIS_WS16, locateByGisWs16Saga)
    yield takeEvery(CREATE_TICKET_FOR_ORDER, createTicketForOrderSaga)
}
