import {useState} from 'react'

export default function useLatLonValidator(isLat = true) {
  const [latLonErrors, setLatLonErrors] = useState('');
  const handleAndValidateLatLon = ({target:{value}}) => {
    const digitError = isNaN(value) || (value && !/^\d+\.?\d*$/.test(value)) ? 'יש להקליד רק מספרים ונקודה' : ''
    let latLonError = ''
    if (isLat) {
      if (!value) {
        latLonError = 'חובה להגדיר קו רוחב'
      } else if (value < 29.4534) {
        latLonError = 'קו הרוחב קטן מזה של מדינת ישראל'
      } else if (value > 33.3356) {
        latLonError = 'קו הרוחב גדול מזה של מדינת ישראל'
      }
    } else {
      if (!value) {
        latLonError = 'חובה להגדיר קו אורך'
      } else if (value < 34.2675) {
        latLonError = 'קו האורך קטן מזה של מדינת ישראל'
      } else if (value > 35.895) {
        latLonError = 'קו האורך גדול מזה של מדינת ישראל'
      }
    }
    setLatLonErrors(digitError || latLonError)
  }
  return [handleAndValidateLatLon, latLonErrors]
};
