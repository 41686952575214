export const SET_IS_CONNECTED = 'SET_IS_CONNECTED'
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER'
export const WATCH_FOR_FIREBASE_AUTH = 'WATCH_FOR_FIREBASE_AUTH'
export const DISCONNECT = 'DISCONNECT'
export const AUTHENTICATE = 'AUTHENTICATE'
export const AUTHENTICATE_IS_LOADING = 'authenticateIsLoading'
export const SET_AUTHENTICATE_IS_LOADING = 'SET_AUTHENTICATE_IS_LOADING'
export const IS_CONNECTED = 'isConnected'
export const LOGGED_IN_USER = 'loggedInUser'

export const LOGOUT = 'LOGOUT'
export const TOKEN = 'token'
export const TENANT_ID = 'tenantId'

export const RESTORE_AIRGAP_SESSION = 'RESTORE_AIRGAP_SESSION'

export const TENANTS = [
  {
    id: 'Ibillin-com-46c0v',
    displayName: 'אעבלין תקשורת',
    url: '/ibillin',
    logo: '',
  },
  {
    id: 'Arara-com-r4uyl',
    displayName: 'ערערה תקשורת',
    url: '/arara',
    logo: '',
  },
  {
    id: 'Kma',
    displayName: 'K.M.A. Advanced Technologies LTD',
    url: '/kma',
    logo: '',
  },
]
