
function stringToColor(string) {
  var hash = 0;
  var i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  var color = '#';
  for (i = 0; i < 3; i += 1) {
    var value = (hash >> (i * 8)) & 0xff;
    color += "00".concat(value.toString(16)).slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}
export function stringAvatar(name) {
  return name ? {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: "".concat(name.split(' ')[0][0]).concat(name.split(' ')[1][0]),
  } : {};
}
