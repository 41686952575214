import Skeleton from 'Components/Skeleton'
import styles from './subscribers.module.scss'

const Subscribers = ({locateSubscriber}) => {

  return <Skeleton className={styles.container}>
    אני מעריך שאין צורך לנהל טבלת מנויים אז הקישור הזה ימחק כנראה
    <button onClick={locateSubscriber}>SUBSCRIBERS API TEST</button>
  </Skeleton>
}

export default Subscribers
