import {Autocomplete} from '@mui/material'
import TextField from '@mui/material/TextField';
import classes from 'classnames'
import LocateCustomerInfraResult from 'Components/Orders/LocateCustomerInfra/LocateCustomerInfraResult'
import {emptyCity, emptyStreet} from 'Containers/AppGeneral/consts'
import {scrollContainerElement} from 'Helpers/utils'
import useIsraeliIdValidator from 'Hooks/useIsraeliIdValidator'
import useNumericDigitsValidator from 'Hooks/useNumericDigitsValidator'
import * as React from 'react'
import {useEffect, useState} from 'react'
import styles from './index.module.scss'

export default function LocateCustomerInfra({
                                              cities = [],
                                              streets = [],
                                              submitRef,
                                              isLocateCustomerInfraSubmitOk,
                                              setIsLocateCustomerInfraSubmitOk,
                                              locateCustomer,
                                              locateCustomerInfraFormValues,
                                              setLocateCustomerInfraFormValues,
                                              customerInfraInfo,
                                              isScrollWanted,
                                              setIsScrollWanted,
                                            }) {

  const [handleIsraeliIdChange, israeliIdErrors] = useIsraeliIdValidator()
  const [handleLineCodeChange, lineCodeErrors] = useNumericDigitsValidator()
  const [handleFourDigitsChange, fourDigitsErrors] = useNumericDigitsValidator({minLength: 4})
  const [handleMobileNumberChange, mobileNumberErrors] = useNumericDigitsValidator()
  const [selectedCityRow, setSelectedCityRow] = useState(locateCustomerInfraFormValues?.cityRow || emptyCity)
  const [selectedStreetRow, setSelectedStreetRow] = useState(locateCustomerInfraFormValues?.streetRow || emptyStreet)
  const [streetsFiltered, setStreetsFiltered] = useState([])

  const formSubmit = e => {
    e.preventDefault()
    if (!isLocateCustomerInfraSubmitOk) {
      return
    }
    var data = new FormData(e.target);
    let formObject = Object.fromEntries(data.entries())
    formObject.cityRow = selectedCityRow
    formObject.streetRow = selectedStreetRow
    setLocateCustomerInfraFormValues(formObject)
    locateCustomer(formObject)
  }

  useEffect(() => {
    const streetsFiltered = streets?.filter((st) => st.cityId === selectedCityRow?.cityId).concat([emptyStreet])
    setStreetsFiltered(streetsFiltered)
  }, [selectedCityRow, streets])

  const validationObject = {}

  function monitor(validatorStateKeyVal) { // runs inside render cycle
    const key = Object.keys(validatorStateKeyVal)[0]
    const value = validatorStateKeyVal[key]
    validationObject[key] = !!value
    return !!value
  }

  useEffect(() => {
    const atLeastOneError = !!Object.values(validationObject).find(item => item)
    setIsLocateCustomerInfraSubmitOk(!atLeastOneError)
  })

  useEffect(() => {
    if (customerInfraInfo && isScrollWanted) {
      scrollContainerElement()
      setIsScrollWanted(false)
    }
  }, [customerInfraInfo, isScrollWanted, setIsScrollWanted])

  return <div className={classes(styles.container)}>
    <form onSubmit={formSubmit}>
      <button ref={submitRef} type="submit" style={{display: 'none'}}/>
      <div className={styles.inARow}>
        <TextField label="תעודת זהות"
                   type="number"
                   name="israeliId"
                   onChange={handleIsraeliIdChange}
                   error={monitor({israeliIdErrors})}
                   helperText={israeliIdErrors}
                   defaultValue={locateCustomerInfraFormValues?.israeliId}
        />

        <TextField label="קוד קו"
                   type="string"
                   name="lineCode"
                   onChange={handleLineCodeChange}
                   error={monitor({lineCodeErrors})}
                   helperText={lineCodeErrors}
                   inputProps={{maxLength: 10}}
                   defaultValue={locateCustomerInfraFormValues?.lineCode}

        />
      </div>
      <div className={styles.inARow}>
        <TextField label="שם פרטי"
                   name="firstName"
                   defaultValue={locateCustomerInfraFormValues?.firstName}
        />
        <TextField label="שם משפחה"
                   name="lastName"
                   defaultValue={locateCustomerInfraFormValues?.lastName}
        />
      </div>
      <div className={styles.inARow}>
        <TextField label="4 ספרות אחרונות כ.אשראי/ה.קבע"
                   type="string"
                   name="fourDigits"
                   onChange={handleFourDigitsChange}
                   error={monitor({fourDigitsErrors})}
                   helperText={fourDigitsErrors}
                   inputProps={{maxLength: 4}}
                   defaultValue={locateCustomerInfraFormValues?.fourDigits}
        />
        <TextField label="טלפון נייד"
                   type="string"
                   name="mobileNumber"
                   onChange={handleMobileNumberChange}
                   error={monitor({mobileNumberErrors})}
                   helperText={mobileNumberErrors}
                   inputProps={{maxLength: 10}}
                   defaultValue={locateCustomerInfraFormValues?.mobileNumber}
        />
      </div>
      <div className={styles.inARow}>
        <Autocomplete
          disablePortal
          label="שם יישוב"
          name="cityRow"
          value={selectedCityRow}
          isOptionEqualToValue={(option, value) => {
            const {addressCount, ..._option} = option;
            const {addressCount: addressCount2, ..._value} = value;

            return JSON.stringify(_option) === JSON.stringify(_value)
          }}
          onChange={(e, value) => {
            setSelectedStreetRow(emptyStreet);
            setSelectedCityRow(value)
          }}
          options={cities.concat([emptyCity])}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="עיר"/>}
        />
        <Autocomplete
          disablePortal
          label="שם רחוב"
          name="streetRow"
          value={selectedStreetRow}
          isOptionEqualToValue={(option, value) => {
            const {addressCount, ..._option} = option;
            const {addressCount: addressCount2, ..._value} = value;
            return JSON.stringify(_option) === JSON.stringify(_value)
          }}
          onChange={(e, value) => setSelectedStreetRow(value)}
          options={streetsFiltered}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="רחוב"/>}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.streetId + option.cityId}>
                {option.name}
              </li>
            );
          }}
        />
      </div>
      <div className={styles.inARow}>
        <TextField label="מספר בית"
                   type="number"
                   name="houseNum"
                   defaultValue={locateCustomerInfraFormValues?.houseNum}
        />
        <TextField label="אות"
                   name="letter"
                   defaultValue={locateCustomerInfraFormValues?.letter}
        />
      </div>
    </form>
    {customerInfraInfo && <LocateCustomerInfraResult customerInfraInfo={customerInfraInfo}/>}
  </div>
}
