import local from './config.local';

const commonConfig = {
    googleMapsKey: "",
}
const configMap = {
    local,
};

const config = {
    ...(configMap[process.env.REACT_APP_ENV] || local),
    ...commonConfig
}
export default config
