import AdminConsole from 'Containers/AdminConsole'
import SysTables from 'Containers/AdminConsole/SysTables/SysTables'
import CheckLines from 'Containers/CheckLines'
import CreateCheckLine from 'Containers/CheckLines/CreateCheckLine/CreateCheckLine'
import Orders from 'Containers/Orders'
import CreateOrder from 'Containers/Orders/CreateOrder/CreateOrder'
import EditOrder from 'Containers/Orders/EditOrder/EditOrder'
import Subscribers from 'Containers/Subscribers'
import Tickets from 'Containers/Tickets'
import CreateTicket from 'Containers/Tickets/CreateTicket/CreateTicket'
import EditTicket from 'Containers/Tickets/EditTicket/EditTicket'
import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {history} from 'Redux/store';
import {ConnectedRouter} from 'connected-react-router/immutable';
import TenantPage from '../../Containers/Auth/TenantPage'
import {TENANTS} from 'Containers/Auth/consts'
import Swagger from 'Containers/Swagger'
import FourZeroFour from './FourZeroFour/FourZeroFour'
import PrivateRoute from './PrivateRoute';
import PublicOrPrivateRoute from './PublicOrPrivateRoute'
import PublicRoute from './PublicRoute'

const Routes = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PrivateRoute exact path="/:tenantId/subscribers" Component={Subscribers} />
        <PrivateRoute exact path="/:tenantId/orders" Component={Orders} />
        <PrivateRoute exact path="/:tenantId/orders/new" Component={CreateOrder} />
        <PrivateRoute exact path="/:tenantId/orders/:orderId" Component={EditOrder} />
        <PrivateRoute exact path="/:tenantId/checklines" Component={CheckLines} />
        <PrivateRoute exact path="/:tenantId/checklines/new" Component={CreateCheckLine} />
        <PrivateRoute exact path="/:tenantId/tickets" Component={Tickets} />
        <PrivateRoute exact path="/:tenantId/tickets/new" Component={CreateTicket} />
        <PrivateRoute exact path="/:tenantId/tickets/new/:orderId" Component={CreateTicket} />
        <PrivateRoute exact path="/:tenantId/tickets/:ticketId" Component={EditTicket} />
        <PrivateRoute exact path="/:tenantId/swagger"><Swagger/></PrivateRoute>
        <PrivateRoute exact path="/:tenantId/admin" Component={AdminConsole} />
        <PrivateRoute exact path="/:tenantId/systables" Component={SysTables} />
        <PrivateRoute exact path="/"/>
        <PublicRoute exact path="/public">No content here</PublicRoute>
        {TENANTS.map(tenant => <PublicOrPrivateRoute key={tenant.id} path={tenant.url} Component={TenantPage}/>)}
        <Route><FourZeroFour/></Route>
      </Switch>
    </ConnectedRouter>
  );
};

export default Routes;
