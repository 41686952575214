import InfiniteScrollTable from 'Components/InfiniteScrollTable/InfiniteScrollTable'
import OrdersContainerHolder from 'Components/Orders/OrdersTable/OrdersContainerHolder'
import {TABLE_DATA} from 'Containers/Orders/consts'
import useRedirect from 'Hooks/useRedirect'
import React, {useCallback, useEffect} from 'react'

const PAGE = 50

const OrdersTable = ({loadOrders, ordersTable, loadOrdersIsLoading, ordersKeyword}) => {
  const {hasMore} = ordersTable
  const tableData = ordersTable?.[TABLE_DATA]
  const redirect = useRedirect()

  useEffect(() => {
    const length = Object.keys(tableData).length
    if (length === 0) {
      loadOrders(0, PAGE, ordersKeyword)
    }
  }, [tableData, loadOrders, ordersKeyword])

  const loadOrdersWithOffset = useCallback(() => {
    const offset = Object.keys(tableData).length
    loadOrders(offset + PAGE, PAGE, ordersKeyword)
  }, [loadOrders, tableData, ordersKeyword])

  const handleClick = useCallback((e, id) => {
    redirect(`/orders/${id}`)
  }, [redirect])


  return <InfiniteScrollTable
    isLoading={loadOrdersIsLoading}
    hasMore={hasMore}
    loadItems={loadOrdersWithOffset}
    TableContainer={OrdersContainerHolder}
    tableProps={{tableData, handleClick}}

  />
}
export default OrdersTable
