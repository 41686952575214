import EditTicketPanel from 'Components/Tickets/EditTicketPanel/EditTicketPanel'
import {getCities, getStreets} from 'Containers/AppGeneral/selectors'
import {createTicketWs8, retrieveTicketWs7} from 'Containers/Tickets/actions'
import {getCreateTicketIsLoading, getRetrieveTicketIsLoading} from 'Containers/Tickets/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    createTicketWs8: (redirect) => dispatch(createTicketWs8(redirect)),
    retrieveTicket: ticketId => dispatch(retrieveTicketWs7(ticketId)),
  }
};
const mapStateToProps = (state) => {
  return {
    createTicketIsLoading: getCreateTicketIsLoading(state),
    retrieveTicketIsLoading: getRetrieveTicketIsLoading(state),
    cities: getCities(state),
    streets: getStreets(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTicketPanel)
