import config from 'Config/config'
import {connect} from 'react-redux'
import AppBar from 'Components/AppBar'
import {getIsConnected, getLoggedInUser} from 'Containers/Auth/selectors'

const mapStateToProps = (state,) => {
  const isConnected = getIsConnected(state)
  const loggedInUser = getLoggedInUser(state)
  const authorizationMethod = config.authorizationMethod

  return {
    isConnected,
    loggedInUser,
    authorizationMethod,
  }
};

export default connect(mapStateToProps,null)(AppBar);
