import {Autocomplete} from '@mui/material'
import TextField from '@mui/material/TextField';
import classes from 'classnames'
import {emptyCity, emptyStreet} from 'Containers/AppGeneral/consts'
import useLatLonValidator from 'Hooks/useLatLonValidator'
import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import styles from './index.module.scss'

export default function LocateByGis({
                                      cities = [],
                                      streets = [],
                                      submitGisRef,
                                      isLocateByGisSubmitOk,
                                      setIsLocateByGisSubmitOk,
                                      doLocateByGis,
                                      locateByGisFormValues,
                                      setLocateByGisFormValues,
                                      locateByGisIsLoading,
                                    }) {
  const [handleLatChange, latErrors] = useLatLonValidator(true)
  const [handleLonChange, lonErrors] = useLatLonValidator(false)

  const [selectedCityRow, setSelectedCityRow] = useState(locateByGisFormValues?.cityRow || emptyCity)
  const [selectedStreetRow, setSelectedStreetRow] = useState(locateByGisFormValues?.streetRow || emptyStreet)
  const [streetsFiltered, setStreetsFiltered] = useState([])
  const formRef = useRef()
  const [isAnimated, setIsAnimated] = useState(false)

  const getFormValues = () => {
    var data = new FormData(formRef.current)
    let formObject = Object.fromEntries(data.entries())
    formObject.cityRow = selectedCityRow
    formObject.streetRow = selectedStreetRow
    return formObject
  }

  const formSubmit = e => {
    e.preventDefault()
    if (!isLocateByGisSubmitOk) {
      return
    }
    const formObject = getFormValues()
    setLocateByGisFormValues(formObject)
    doLocateByGis(formObject)
  }

  useEffect(() => {
    const streetsFiltered = streets?.filter((st) => st.cityId === selectedCityRow?.cityId).concat([emptyStreet])
    setStreetsFiltered(streetsFiltered)
  }, [selectedCityRow, streets])

  const validationObject = {}

  function monitor(validatorStateKeyVal) { // runs inside render cycle
    const key = Object.keys(validatorStateKeyVal)[0]
    const value = validatorStateKeyVal[key]
    validationObject[key] = !!value
    return !!value
  }
  useEffect(() => {
    const atLeastOneError = !!Object.values(validationObject).find(item => item)
    const formValues = getFormValues()
    const areValuesMissing = !formValues.x || !formValues.y
    setIsLocateByGisSubmitOk(!atLeastOneError && !areValuesMissing)
  })

  useEffect( () => {
    if (!locateByGisIsLoading && selectedStreetRow !== emptyStreet) {
      setIsAnimated(true)
      setTimeout(() => {
        setIsAnimated(false)
      },300)
    }
  }, [selectedStreetRow, locateByGisIsLoading])

  return <div className={classes(styles.container)}>
    <form onSubmit={formSubmit} ref={formRef} className={classes({[styles.animateTextField]:isAnimated})}>
      <button ref={submitGisRef} type="submit" style={{display: 'none'}}/>

      <Autocomplete
        disablePortal
        label="שם יישוב"
        name="cityRow"
        value={selectedCityRow}
        isOptionEqualToValue={(option, value) => {
          const {addressCount, ..._option} = option;
          const {addressCount:addressCount2, ..._value} = value;
          return JSON.stringify(_option) === JSON.stringify(_value)
        }}
        onChange={(e, value) => {
          setSelectedStreetRow(emptyStreet);
          setSelectedCityRow(value)
        }}
        options={cities.concat([emptyCity])}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="עיר"/>}
      />
      <Autocomplete
        disablePortal
        label="שם רחוב"
        name="streetRow"
        readOnly={true}
        disabled={true}
        value={selectedStreetRow}
        isOptionEqualToValue={(option, value) => {
          const {addressCount, ..._option} = option;
          const {addressCount:addressCount2, ..._value} = value;
          return JSON.stringify(_option) === JSON.stringify(_value)
        }}
        onChange={(e, value) => setSelectedStreetRow(value)}
        options={streetsFiltered}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="רחוב"/>}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.streetId + option.cityId}>
              {option.name}
            </li>
          );
        }}
      />
      <TextField label="מספר בית"
                 type="number"
                 name="houseNum"
                 disabled={true}
                 defaultValue={locateByGisFormValues?.houseNum}
                 inputProps={{readOnly: true}}
      />
      <TextField label="אות"
                 name="houseLetter"
                 disabled={true}
                 defaultValue={locateByGisFormValues?.houseLetter}
                 inputProps={{readOnly: true}}
      />
      <TextField label="Lat / Y / קו רוחב"
                 name="y"
                 defaultValue={locateByGisFormValues?.y}
                 onChange={handleLatChange}
                 error={monitor({latErrors})}
                 helperText={latErrors}
                 required
      />
      <TextField label="Lon / X / קו אורך"
                 name="x"
                 defaultValue={locateByGisFormValues?.x}
                 onChange={handleLonChange}
                 error={monitor({lonErrors})}
                 helperText={lonErrors}
                 required
      />
    </form>
  </div>
}
