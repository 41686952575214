import {LOCATE_SUBSCRIBER_RESULT, SUBSCRIBER_DATA} from 'Containers/Subscribers/consts'
import {fromJS} from "immutable";

const initialState = fromJS({
    [SUBSCRIBER_DATA]: {},
});

export default function SubscribersReducer(state = initialState, action){
    switch (action.type) {
        case LOCATE_SUBSCRIBER_RESULT:
            return state.set(SUBSCRIBER_DATA, action.result)
        default:
            return state
    }
}
