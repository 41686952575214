import {AIRGAP_AUTH} from 'Redux/consts'

function getBackend() {
    const currentDomain = window.location.hostname.split('.')[0]
    let backend
    switch (currentDomain) {
        case 'localhost':
            backend = 'https://ferret.geocell.systems'
            break
        case '127.0.0.1':
            backend = 'https://ferret.geocell.systems'
            break
        case 'bez-dev':
            backend = 'https://squirrel.geocell.systems'
            break
        case 'bez-test':
            backend = 'https://ferret.geocell.systems'
            break
        default:
            throw `could not resolve which backend to use for ${window.location.hostname}`
    }
    return backend
}
const config = {
    googleClientId: '',
    firebaseConfig: {
        apiKey: "AIzaSyCsUZ1CwJmaSMGjj3q5GtcEZjrdxEtgyj0",
        authDomain: "geobez-93b3a.firebaseapp.com",
        projectId: "geobez-93b3a",
        storageBucket: "geobez-93b3a.appspot.com",
        messagingSenderId: "375437298311",
        appId: "1:375437298311:web:012bda41b93da3a5ce0622"
    },
    backendServer: getBackend(),
    apiServer: `${getBackend()}/api/`,
    swaggerServer: `${getBackend()}/swagger/`,
    apiMockupServer: `${getBackend()}/api/mockup/`,
    // authorizationMethod: FIREBASE_AUTH,
    authorizationMethod: AIRGAP_AUTH,
}
export default config

