import {
  ACTIVATE_GPON_WS15,
  CREATE_ORDER_WS2, CREATE_TICKET_FOR_ORDER, FINALIZE_ORDER_WS12, FINALIZE_ORDER_WS12_RESULT, LOAD_ORDERS, LOAD_ORDERS_RESULT, LOAD_SINGLE_ORDER, LOAD_SINGLE_ORDER_RESULT, LOCATE_BY_GIS_WS16, LOCATE_BY_GIS_WS16_RESULT,
  LOCATE_CUSTOMER_WS1, LOCATE_CUSTOMER_WS1_RESULT, RETRIEVE_ORDER_WS3, RETRIEVE_ORDER_WS3_RESULT, SET_ACTIVATE_GPON_IS_LOADING, SET_CREATE_ORDER_FORM_VALUES,
  SET_CREATE_ORDER_IS_LOADING, SET_CREATE_TICKET_IS_LOADING,
  SET_EDIT_ORDER_FORM_VALUES, SET_FINALIZE_ORDER_IS_LOADING, SET_LOAD_ORDERS_IS_LOADING,
  SET_LOAD_SINGLE_ORDER_IS_LOADING, SET_LOCATE_BY_GIS_FORM_VALUES, SET_LOCATE_BY_GIS_IS_LOADING,
  SET_LOCATE_CUSTOMER_INFRA_FORM_VALUES,
  SET_LOCATE_CUSTOMER_INFRA_IS_LOADING, SET_ORDERS_KEYWORD, SET_RETRIEVE_ORDER_IS_LOADING,
} from 'Containers/Orders/consts'

export function locateCustomerWs1(formData) {
  return {
    type: LOCATE_CUSTOMER_WS1,
    formData
  }
}

export function setLocateCustomerInfraFormValues(formValues) {
  return {
    type: SET_LOCATE_CUSTOMER_INFRA_FORM_VALUES,
    formValues
  }
}

export function locateCustomerWs1Result(result) {
  return {
    type: LOCATE_CUSTOMER_WS1_RESULT,
    result,
  }
}

export function setLocateCustomerInfraIsLoading(isLoading) {
  return {
    type: SET_LOCATE_CUSTOMER_INFRA_IS_LOADING,
    isLoading
  }
}

export function setEditOrderFormValues(formValues = {}) {
  return {
    type: SET_EDIT_ORDER_FORM_VALUES,
    formValues
  }
}

export function setCreateOrderFormValues(formValues = {}) {
  return {
    type: SET_CREATE_ORDER_FORM_VALUES,
    formValues
  }
}

export function setCreateOrderIsLoading(isLoading) {
  return {
    type: SET_CREATE_ORDER_IS_LOADING,
    isLoading
  }
}

export function createOrderWs2(redirect) {
  return {
    type: CREATE_ORDER_WS2,
    redirect,
  }
}


export function loadOrders(offset, limit, keyword) {
  return {
    type: LOAD_ORDERS,
    offset,
    limit,
    keyword,
  }
}
export function loadOrdersResult(result){
    return {
        type: LOAD_ORDERS_RESULT,
        result
    }
}
export function setLoadOrdersIsLoading(isLoading){
    return {
        type: SET_LOAD_ORDERS_IS_LOADING,
        isLoading
    }
}
export function loadSingleOrder(orderId){
    return {
        type: LOAD_SINGLE_ORDER,
        orderId
    }
}
export function loadSingleOrderResult(result = {}){
    return {
        type: LOAD_SINGLE_ORDER_RESULT,
        result
    }
}
export function setLoadSingleOrderIsLoading(isLoading){
    return {
        type: SET_LOAD_SINGLE_ORDER_IS_LOADING,
        isLoading
    }
}
export function retrieveOrderWs3(orderId){
    return {
        type: RETRIEVE_ORDER_WS3,
        orderId
    }
}
export function retrieveOrderWs3Result(result){
    return {
        type: RETRIEVE_ORDER_WS3_RESULT,
        result
    }
}
export function setRetrieveOrderIsLoading(isLoading){
    return {
        type: SET_RETRIEVE_ORDER_IS_LOADING,
        isLoading
    }
}
export function setOrdersKeyword(keyword){
  return {
    type: SET_ORDERS_KEYWORD,
    keyword
  }
}
export function finalizeOrderWs12(orderId){
    return {
        type: FINALIZE_ORDER_WS12,
        orderId
    }
}
export function finalizeOrderWs12Result(result){
    return {
        type: FINALIZE_ORDER_WS12_RESULT,
        result
    }
}
export function setFinalizeOrderIsLoading(isLoading){
    return {
        type: SET_FINALIZE_ORDER_IS_LOADING,
        isLoading
    }
}
export function activateGponWs15(orderId, serialNumber, planSoftware){
    return {
        type: ACTIVATE_GPON_WS15,
        orderId,
        serialNumber,
        planSoftware,
    }
}
export function setActivateGponIsLoading(isLoading){
    return {
        type: SET_ACTIVATE_GPON_IS_LOADING,
        isLoading
    }
}
export function locateByGisWs16(formData){
    return {
        type: LOCATE_BY_GIS_WS16,
        formData
    }
}
export function locateByGisWs16Result(result){
    return {
        type: LOCATE_BY_GIS_WS16_RESULT,
        result
    }
}
export function setLocateByGisIsLoading(isLoading){
    return {
        type: SET_LOCATE_BY_GIS_IS_LOADING,
        isLoading
    }
}
export function setLocateByGisFormValues(formValues) {
  return {
    type: SET_LOCATE_BY_GIS_FORM_VALUES,
    formValues
  }
}
export function createTicketForOrder(redirect, orderId, orderFormValues){
    return {
        type: CREATE_TICKET_FOR_ORDER,
        redirect,
        orderId,
        orderFormValues
    }
}

export function setCreateTicketIsLoading(isLoading){
    return {
        type: SET_CREATE_TICKET_IS_LOADING,
        isLoading
    }
}
