import {setIsLoading} from 'Containers/AppGeneral/actions'
import {locateSubscriberResult} from 'Containers/Subscribers/actions'
import {takeEvery, call, put} from 'redux-saga/effects';
import {LOCATE_SUBSCRIBER} from "./consts";
import api from 'Api/Backend'

function *locateSubscriberSaga() {
    yield put(setIsLoading(true))
    const result = yield call(api.get.bind(api), 'LocatingSubscriber/IdNumber/52423')
    yield put(locateSubscriberResult(result))
    yield put(setIsLoading(false))
}

export default function* SubscribersSaga() {
    yield takeEvery(LOCATE_SUBSCRIBER, locateSubscriberSaga);
}
