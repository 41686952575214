import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import styles from 'Components/Tickets/TicketsTable/index.module.scss'
import {TASK_STATUS_CODE} from 'Containers/AppGeneral/consts'
import {TABLE_DATA} from 'Containers/Tickets/consts'
import React from 'react'

const TicketsContainerHolder = React.forwardRef((props, ref) => {
  const {handleClick} = props
  const tableData = props?.[TABLE_DATA]
  return <TableContainer className={styles.tableContainerHolder}  ref={ref}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className={styles.notOnMobile}>מזהה</TableCell>
          <TableCell className={styles.notOnMobile}>הזמנה</TableCell>
          <TableCell>קוד קו</TableCell>
          <TableCell className={styles.notOnMobile}>טלפון איש קשר</TableCell>
          <TableCell>איש קשר</TableCell>
          <TableCell>סטטוס משימה</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(tableData).map(id => (
          <TableRow key={id}
                    hover
                    onClick={(event) => handleClick(event, id)}
          >
            <TableCell className={styles.notOnMobile}>{id}</TableCell>
            <TableCell className={styles.notOnMobile}>{tableData[id].orderId}</TableCell>
            <TableCell>{tableData[id].lineCode}</TableCell>
            <TableCell className={styles.notOnMobile}>{tableData[id].contactPhoneNumber}</TableCell>
            <TableCell>{tableData[id].contactName}</TableCell>
            <TableCell>{TASK_STATUS_CODE?.[Number(tableData[id].taskStatusCode)]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
})

export default TicketsContainerHolder
