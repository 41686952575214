import {FacebookCircularProgress} from 'Components/Generic/Spinner'
import Skeleton from 'Components/Skeleton'
import EditOrderPanel from 'Containers/Orders/EditOrderPanel/EditOrderPanel'
import {useEffect, useMemo, useState} from 'react'
import styles from './EditOrder.module.scss'

const EditOrder = ({orderId, loadSingleOrder, singleOrder = {}, loadSingleOrderIsLoading, editOrderFormValues, setEditOrderFormValues}) => {

  const [editOrderComponentKey, setEditOrderComponentKey] = useState(0)

  // clear/reload EditOrderPanel on a freshly loaded order
  useMemo(() => {
    setEditOrderComponentKey(prev => prev + 1)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [singleOrder, editOrderFormValues, setEditOrderComponentKey])

  useEffect(() => {
    if (orderId) {
      loadSingleOrder(orderId)
    }
  }, [orderId, loadSingleOrder])


  const {bezeqOrderId} = singleOrder

  return <Skeleton className={styles.orderPageContainer}>
    {loadSingleOrderIsLoading ? <div className={styles.spinnerContainer}><FacebookCircularProgress/></div>
      :
      <>
        <div className={styles.orderHeaderContainer}>
          <h3>הזמנה מס׳ {orderId} &nbsp;
            {bezeqOrderId && <span>(הזמנה מס׳ {bezeqOrderId} בבזק)</span>}
          </h3>
        </div>
        <EditOrderPanel orderId={orderId} key={`editOrder${editOrderComponentKey}`} formValues={editOrderFormValues} setFormValues={setEditOrderFormValues} singleOrder={singleOrder}/>
      </>
    }
  </Skeleton>
}

export default EditOrder
