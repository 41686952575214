import Orders from 'Components/Orders/Orders'
import {setOrdersKeyword} from 'Containers/Orders/actions'
import {getOrdersKeyword} from 'Containers/Orders/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    setOrdersKeyword: keyword => dispatch(setOrdersKeyword(keyword))
  }
};
const mapStateToProps = (state) => {
  return {
    ordersKeyword: getOrdersKeyword(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
