import {LOCATE_SUBSCRIBER, LOCATE_SUBSCRIBER_RESULT} from 'Containers/Subscribers/consts'

export function locateSubscriber({locateType, idNumber, lineCode}) {
  return {
    type: LOCATE_SUBSCRIBER,
    locateType,
    idNumber,
    lineCode
  }
}
export function locateSubscriberResult(result){
    return {
        type: LOCATE_SUBSCRIBER_RESULT,
        result
    }
}

