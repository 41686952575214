import EditOrderPanel from 'Components/Orders/EditOrderPanel/EditOrderPanel'
import {setIsScrollWanted} from 'Containers/AppGeneral/actions'
import {getCities, getIsScrollWanted, getStreets} from 'Containers/AppGeneral/selectors'
import {activateGponWs15, createOrderWs2, createTicketForOrder, finalizeOrderWs12, retrieveOrderWs3} from 'Containers/Orders/actions'
import {getActivateGponIsLoading, getCreateOrderIsLoading, getCustomerInfraInfo, getFinalizeOrderIsLoading, getLocateByGisIsLoading, getLocateCustomerInfraIsLoading, getRetrieveOrderIsLoading} from 'Containers/Orders/selectors'
import {getCreateTicketIsLoading} from 'Containers/Tickets/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    createOrderWs2: (redirect) => dispatch(createOrderWs2(redirect)),
    setIsScrollWanted: value => dispatch(setIsScrollWanted(value)),
    retrieveOrder: orderId => dispatch(retrieveOrderWs3(orderId)),
    finalizeOrderWs12: (orderId) => dispatch(finalizeOrderWs12(orderId)),
    activateGponWs15: (orderId, serialNumber, planSoftware) => dispatch(activateGponWs15(orderId, serialNumber, planSoftware)),
    createTicketForOrder: (redirect, orderId, orderFormValues) => dispatch(createTicketForOrder(redirect, orderId,  orderFormValues)),
  }
};
const mapStateToProps = (state) => {
  return {
    locateCustomerInfraIsLoading: getLocateCustomerInfraIsLoading(state),
    customerInfraInfo: getCustomerInfraInfo(state),
    cities: getCities(state),
    streets: getStreets(state),
    createOrderIsLoading: getCreateOrderIsLoading(state),
    isScrollWanted: getIsScrollWanted(state),
    retrieveOrderIsLoading: getRetrieveOrderIsLoading(state),
    finalizeOrderIsLoading: getFinalizeOrderIsLoading(state),
    activateGponIsLoading: getActivateGponIsLoading(state),
    locateByGisIsLoading: getLocateByGisIsLoading(state),
    createTicketIsLoading: getCreateTicketIsLoading(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderPanel)
