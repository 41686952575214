import React, {useMemo} from 'react'
import {Provider} from 'react-redux'
import store from './Redux/store'
import 'core-js'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './Containers/Routes'
import OnLoad from "./Containers/AppGeneral"
import {deepmerge} from '@mui/utils'
import {experimental_extendTheme as extendMuiTheme} from '@mui/material/styles'
import {
  extendTheme as extendJoyTheme,
  CssVarsProvider,
} from '@mui/joy/styles'
import './App.module.scss'

function App() {
  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", "rtl")
  }, []);

  const theme = useMemo(() => {
    const _mui = extendMuiTheme()
    const _joy = extendJoyTheme()
    const {unstable_sxConfig, cssVarPrefix, direction, ...muiTheme} = {..._mui}
    const {direction: direction1, ...joyTheme} = {..._joy}
    const newMui = {direction: 'rtl', ...muiTheme}
    const newJoy = {direction: 'rtl', ...joyTheme}
    return deepmerge(newJoy, newMui)
  }, [])

  return (
    <Provider store={store}>
      <CssVarsProvider theme={theme}>
        <OnLoad/>
        <Routes/>
        <ToastContainer style={{width: '360px'}}/>
      </CssVarsProvider>
    </Provider>
  );
}

export default App;
