import classes from 'classnames'
import ErrorBoundary from 'Components/Generic/ErrorBoundary'
import styles from './index.module.scss'
import AppBar from 'Containers/AppBar'
import React from 'react'

const Skeleton = ({children, className}) => {
  return <div className={styles.container}>
    <header className={styles.header}>
      <AppBar/>
    </header>
    <div className={classes(styles.content)}>
      <ErrorBoundary>
        <div className={className}>
          {children}
        </div>
      </ErrorBoundary>
    </div>
    <footer className={styles.footer}>כל הזכויות שמורות © 2023 גיאוסל מערכות בע"מ</footer>
  </div>
}
export default Skeleton
