
export class SplitLineTextStream extends TransformStream {
  constructor() {
    super({
      buf:'',
      transform(chunk, controller) {
        let temp = chunk.split('\n')
        this.buf += temp[0]
        if (this.buf) controller.enqueue(this.buf)// + (temp.length > 1 ? '\n' : ''))
        this.buf = ''
        for (let i = 1; i < temp.length - 1; i++) {
          if (temp[i]) controller.enqueue(temp[i])// + '\n')
        }
        this.buf += temp[temp.length - 1]
      }
    })
  }

}

export class LineBreaksTransformStream {
  constructor(...strategies) {
    const { writable, readable } = new TransformStream({}, ...strategies);
    this.writable = writable
    this.readable = readable
      // eslint-disable-next-line no-undef
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(new SplitLineTextStream())
      // eslint-disable-next-line no-undef
      .pipeThrough(new TextEncoderStream())
  }
}
export class IndifferentTransformStream {
  constructor(...strategies) {
    const { writable, readable } = new TransformStream({}, ...strategies);
    this.writable = writable
    this.readable = readable
      // eslint-disable-next-line no-undef
      .pipeThrough(new TextDecoderStream())
      // .pipeThrough(new SplitLineTextStream())
      // eslint-disable-next-line no-undef
      .pipeThrough(new TextEncoderStream())
  }
}
