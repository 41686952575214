import CreateCheckLine from 'Components/CheckLines/CreateCheckLine/CreateCheckLine'
import {createCheckLineRequestWs4} from 'Containers/CheckLines/actions'
import {getCheckLineRequestInfo, getCheckLineRequestIsLoading} from 'Containers/CheckLines/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    createCheckLineRequestWs4: (request) => dispatch(createCheckLineRequestWs4(request))
  }
};
const mapStateToProps = (state,) => {
  return {
    checkLineRequestIsLoading: getCheckLineRequestIsLoading(state),
    checkLineRequestInfo: getCheckLineRequestInfo(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCheckLine)
