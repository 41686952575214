import modalStyles from 'Components/Generic/BasicModal.module.scss'

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function scrollContainerElement(classToScroll = modalStyles.scrollableContainer) {
  for(const objDiv of Array.from(document.getElementsByClassName(classToScroll))){
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight
  }
}
