import InfiniteScrollTable from 'Components/InfiniteScrollTable/InfiniteScrollTable'
import TicketsContainerHolder from 'Components/Tickets/TicketsTable/TicketsContainerHolder'
import {TABLE_DATA} from 'Containers/Tickets/consts'
import useRedirect from 'Hooks/useRedirect'
import React, {useCallback, useEffect} from 'react'

const PAGE = 50

const TicketsTable = ({loadTickets, ticketsTable, loadTicketsIsLoading, ticketsKeyword}) => {
  const {hasMore} = ticketsTable
  const tableData = ticketsTable?.[TABLE_DATA]
  const redirect = useRedirect()

  useEffect(() => {
    const length = Object.keys(tableData).length
    if (length === 0) {
      loadTickets(0, PAGE, ticketsKeyword)
    }
  }, [tableData, loadTickets, ticketsKeyword])

  const loadTicketsWithOffset = useCallback(() => {
    const offset = Object.keys(tableData).length
    loadTickets(offset + PAGE, PAGE, ticketsKeyword)
  }, [loadTickets, tableData, ticketsKeyword])

  const handleClick = useCallback((e, id) => {
    redirect(`/tickets/${id}`)
  }, [redirect])


  return <InfiniteScrollTable
    isLoading={loadTicketsIsLoading}
    hasMore={hasMore}
    loadItems={loadTicketsWithOffset}
    TableContainer={TicketsContainerHolder}
    tableProps={{tableData, handleClick}}

  />
}
export default TicketsTable
