import {fromJS,merge} from "immutable";
import {
    CREATE_ORDER_FORM_VALUES,
    CREATE_ORDER_IS_LOADING,
    CUSTOMER_INFRA_INFO,
    EDIT_ORDER_FORM_VALUES,
    HAS_MORE,
    LOAD_ORDERS_IS_LOADING,
    LOAD_ORDERS_RESULT,
    LOAD_SINGLE_ORDER_IS_LOADING,
    LOAD_SINGLE_ORDER_RESULT,
    LOCATE_CUSTOMER_INFRA_FORM_VALUES,
    LOCATE_CUSTOMER_INFRA_IS_LOADING,
    LOCATE_CUSTOMER_WS1_RESULT,
    TABLE_DATA,
    ORDERS_TABLE,
    RETRIEVE_ORDER_IS_LOADING,
    RETRIEVE_ORDER_WS3_RESULT,
    SET_CREATE_ORDER_FORM_VALUES,
    SET_CREATE_ORDER_IS_LOADING,
    SET_EDIT_ORDER_FORM_VALUES,
    SET_LOAD_ORDERS_IS_LOADING,
    SET_LOAD_SINGLE_ORDER_IS_LOADING,
    SET_LOCATE_CUSTOMER_INFRA_FORM_VALUES,
    SET_LOCATE_CUSTOMER_INFRA_IS_LOADING,
    SET_RETRIEVE_ORDER_IS_LOADING,
    SINGLE_ORDER,
    ORDER_RETRIEVALS,
    SET_ORDERS_KEYWORD,
    ORDERS_KEYWORD,
    FINALIZE_ORDER_IS_LOADING,
    SET_FINALIZE_ORDER_IS_LOADING,
    ACTIVATE_GPON_IS_LOADING,
    SET_ACTIVATE_GPON_IS_LOADING,
    LOCATE_BY_GIS_IS_LOADING,
    SET_LOCATE_BY_GIS_IS_LOADING,
    LOCATE_BY_GIS_WS16_RESULT, LOCATED_BOX_INFO, LOCATE_BY_GIS_FORM_VALUES, SET_LOCATE_BY_GIS_FORM_VALUES, SET_CREATE_TICKET_IS_LOADING, CREATE_TICKET_IS_LOADING
} from './consts'

const emptyOrdersTable = {
    [HAS_MORE]: false,
    [TABLE_DATA]: {},
}
const emptySingleOrder = {

}
const initialState = fromJS({
    [LOCATE_CUSTOMER_INFRA_FORM_VALUES]: null,
    [CUSTOMER_INFRA_INFO]: null,
    [LOCATE_CUSTOMER_INFRA_IS_LOADING]: false,
    [EDIT_ORDER_FORM_VALUES]: null,
    [CREATE_ORDER_FORM_VALUES]: null,
    [CREATE_ORDER_IS_LOADING]: false,
    [ORDERS_TABLE]: emptyOrdersTable,
    [LOAD_ORDERS_IS_LOADING]: false,
    [SINGLE_ORDER]: emptySingleOrder,
    [LOAD_SINGLE_ORDER_IS_LOADING]: false,
    [RETRIEVE_ORDER_IS_LOADING]: false,
    [ORDERS_KEYWORD]: '',
    [FINALIZE_ORDER_IS_LOADING]: false,
    [ACTIVATE_GPON_IS_LOADING]: false,
    [LOCATED_BOX_INFO]: null,
    [LOCATE_BY_GIS_IS_LOADING]: false,
    [LOCATE_BY_GIS_FORM_VALUES]: null,
});

export default function OrdersReducer(state = initialState, action){
    switch (action.type) {
        case SET_LOCATE_CUSTOMER_INFRA_FORM_VALUES:
            return state.set(LOCATE_CUSTOMER_INFRA_FORM_VALUES, fromJS(action.formValues))
        case LOCATE_CUSTOMER_WS1_RESULT:
            return state.set(CUSTOMER_INFRA_INFO, fromJS(action.result))
        case SET_LOCATE_CUSTOMER_INFRA_IS_LOADING:
            return state.set(LOCATE_CUSTOMER_INFRA_IS_LOADING, action.isLoading)
        case SET_EDIT_ORDER_FORM_VALUES:
            return state.set(EDIT_ORDER_FORM_VALUES, fromJS(action.formValues))
        case SET_CREATE_ORDER_FORM_VALUES:
            return state.set(CREATE_ORDER_FORM_VALUES, fromJS(action.formValues))
        case SET_CREATE_ORDER_IS_LOADING:
            return state.set(CREATE_ORDER_IS_LOADING,  action.isLoading)
        case LOAD_ORDERS_RESULT:
            return state.updateIn([ORDERS_TABLE, TABLE_DATA],
                orders => merge(orders, fromJS(action.result.orders)))
              .setIn([ORDERS_TABLE, HAS_MORE],action.result.hasMore)
        case SET_LOAD_ORDERS_IS_LOADING:
            return state.set(LOAD_ORDERS_IS_LOADING, action.isLoading)
        case LOAD_SINGLE_ORDER_RESULT:
            return state.set(SINGLE_ORDER, fromJS(action.result))
        case SET_LOAD_SINGLE_ORDER_IS_LOADING:
            return state.set(LOAD_SINGLE_ORDER_IS_LOADING, action.isLoading)
        case SET_RETRIEVE_ORDER_IS_LOADING:
            return state.set(RETRIEVE_ORDER_IS_LOADING, action.isLoading)
        case RETRIEVE_ORDER_WS3_RESULT:
            return state.updateIn([SINGLE_ORDER, ORDER_RETRIEVALS],
              retrievals => retrievals.unshift(fromJS(action.result.response)))
        case SET_ORDERS_KEYWORD:
            return state.set(ORDERS_KEYWORD, action.keyword).set(ORDERS_TABLE,fromJS(emptyOrdersTable))
        case SET_FINALIZE_ORDER_IS_LOADING:
            return state.set(FINALIZE_ORDER_IS_LOADING, action.isLoading)
        case SET_ACTIVATE_GPON_IS_LOADING:
            return state.set(ACTIVATE_GPON_IS_LOADING, action.isLoading)
        case LOCATE_BY_GIS_WS16_RESULT:
            return state.set(LOCATED_BOX_INFO, fromJS(action.result))
        case SET_LOCATE_BY_GIS_IS_LOADING:
            return state.set(LOCATE_BY_GIS_IS_LOADING, action.isLoading)
        case SET_LOCATE_BY_GIS_FORM_VALUES:
            return state.set(LOCATE_BY_GIS_FORM_VALUES, fromJS(action.formValues))
        case SET_CREATE_TICKET_IS_LOADING:
            return state.set(CREATE_TICKET_IS_LOADING, action.isLoading)
        default:
            return state
    }
}
