export const LOCATE_CUSTOMER_WS1 = 'LOCATE_CUSTOMER_WS1'
export const LOCATE_CUSTOMER_WS1_RESULT = 'LOCATE_CUSTOMER_WS1_RESULT'
export const CUSTOMER_INFRA_INFO = 'customerInfraInfo'

export const CREATE_ORDER_WS2 = 'CREATE_ORDER_WS2'

export const RETRIEVE_ORDER_WS3 = 'RETRIEVE_ORDER_WS3'
export const RETRIEVE_ORDER_WS3_RESULT = 'RETRIEVE_ORDER_WS3_RESULT'
export const RETRIEVE_ORDER_IS_LOADING = 'retrieveOrderIsLoading'
export const SET_RETRIEVE_ORDER_IS_LOADING = 'SET_RETRIEVE_ORDER_IS_LOADING'

export const LOCATE_CUSTOMER_INFRA_FORM_VALUES = 'locateCustomerInfraFormValues'
export const SET_LOCATE_CUSTOMER_INFRA_FORM_VALUES = 'SET_LOCATE_CUSTOMER_INFRA_FORM_VALUES'

export const LOCATE_CUSTOMER_INFRA_IS_LOADING = 'locateCustomerInfraIsLoading'
export const SET_LOCATE_CUSTOMER_INFRA_IS_LOADING = 'SET_LOCATE_CUSTOMER_INFRA_IS_LOADING'

export const EDIT_ORDER_FORM_VALUES = 'editOrderFormValues'
export const SET_EDIT_ORDER_FORM_VALUES = 'SET_EDIT_ORDER_FORM_VALUES'

export const CREATE_ORDER_FORM_VALUES = 'createOrderFormValues'
export const SET_CREATE_ORDER_FORM_VALUES = 'SET_CREATE_ORDER_FORM_VALUES'

export const CREATE_ORDER_IS_LOADING = 'createOrderIsLoading'
export const SET_CREATE_ORDER_IS_LOADING = 'SET_CREATE_ORDER_IS_LOADING'

export const LOAD_ORDERS = 'LOAD_ORDERS'
export const LOAD_ORDERS_RESULT = 'LOAD_ORDERS_RESULT'
export const ORDERS_TABLE = 'ordersTable'
export const TABLE_DATA = 'tableData'
export const HAS_MORE = 'hasMore'
export const LOAD_ORDERS_IS_LOADING = 'loadOrdersIsLoading'
export const SET_LOAD_ORDERS_IS_LOADING = 'SET_LOAD_ORDERS_IS_LOADING'

export const LOAD_SINGLE_ORDER = 'LOAD_SINGLE_ORDER'
export const LOAD_SINGLE_ORDER_RESULT = 'LOAD_SINGLE_ORDER_RESULT'
export const SINGLE_ORDER = 'singleOrder'
export const ORDER_RETRIEVALS = 'orderRetrievals'

export const SET_LOAD_SINGLE_ORDER_IS_LOADING = 'SET_LOAD_SINGLE_ORDER_IS_LOADING'
export const LOAD_SINGLE_ORDER_IS_LOADING = 'loadSingleOrderIsLoading'

export const ORDERS_KEYWORD = 'ordersKeyword'
export const SET_ORDERS_KEYWORD = 'SET_ORDERS_KEYWORD'

export const FINALIZE_ORDER_WS12 = 'FINALIZE_ORDER_WS12'
export const FINALIZE_ORDER_WS12_RESULT = 'FINALIZE_ORDER_WS12_RESULT'
export const FINALIZED_ORDER_INFO = 'finalizedOrderInfo'
export const SET_FINALIZE_ORDER_IS_LOADING = 'SET_FINALIZE_ORDER_IS_LOADING'
export const FINALIZE_ORDER_IS_LOADING = 'finalizeOrderIsLoading'

export const ACTIVATE_GPON_WS15 = 'ACTIVATE_GPON_WS15'
export const ACTIVATE_GPON_WS15_RESULT = 'ACTIVATE_GPON_WS15_RESULT'
export const SET_ACTIVATE_GPON_IS_LOADING = 'SET_ACTIVATE_GPON_IS_LOADING'
export const ACTIVATE_GPON_IS_LOADING = 'activateGponIsLoading'

export const LOCATE_BY_GIS_WS16 = 'LOCATE_BY_GIS_WS16'
export const LOCATE_BY_GIS_WS16_RESULT = 'LOCATE_BY_GIS_WS16_RESULT'
export const LOCATED_BOX_INFO = 'locatedBoxInfo'
export const SET_LOCATE_BY_GIS_IS_LOADING = 'SET_LOCATE_BY_GIS_IS_LOADING'
export const LOCATE_BY_GIS_IS_LOADING = 'locateByGisIsLoading'
export const SET_LOCATE_BY_GIS_FORM_VALUES = 'SET_LOCATE_BY_GIS_FORM_VALUES'
export const LOCATE_BY_GIS_FORM_VALUES = 'locateByGisFormValues'

export const CREATE_TICKET_FOR_ORDER = 'CREATE_TICKET_FOR_ORDER'
export const CREATE_TICKET_IS_LOADING = 'createTicketIsLoading'
export const SET_CREATE_TICKET_IS_LOADING = 'SET_CREATE_TICKET_IS_LOADING'
